import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** Represents an instant on the global timeline, with nanosecond resolution. */
  Instant: { input: any; output: any; }
  /** LocalDate is an immutable struct representing a date within the calendar, with no reference to a particular time zone or time of day. */
  LocalDate: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ActivationCode = {
  __typename?: 'ActivationCode';
  /** Returns  */
  availableTenants?: Maybe<AvailableTenantsConnection>;
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<ActivationCodeItem>;
};


export type ActivationCodeAvailableTenantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivationCodeCreateError = CodeExistsError | InvalidSerialError | OwnedUnitError;

export type ActivationCodeCreateInput = {
  serials: Array<Scalars['String']['input']>;
};

export type ActivationCodeCreatePayload = {
  __typename?: 'ActivationCodeCreatePayload';
  code?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<ActivationCodeCreateError>>;
  id?: Maybe<Scalars['UUID']['output']>;
};

/** Water Station Item for Activation Code. */
export type ActivationCodeItem = {
  __typename?: 'ActivationCodeItem';
  /** Model Number of Water Station. */
  model: Scalars['String']['output'];
  /** Serial Number of Water Station. */
  serial: Scalars['String']['output'];
};

export type ActivationCodeNotFoundError = Error & {
  __typename?: 'ActivationCodeNotFoundError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AtLeastOneSuperUserRequiredError = Error & {
  __typename?: 'AtLeastOneSuperUserRequiredError';
  message: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type AvailableTenantsConnection = {
  __typename?: 'AvailableTenantsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AvailableTenantsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Tenant>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AvailableTenantsEdge = {
  __typename?: 'AvailableTenantsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Tenant;
};

export type CodeExistsError = Error & {
  __typename?: 'CodeExistsError';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  serial: Scalars['String']['output'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type DispatchedHistoryItem = WaterStationHistoryItem & {
  __typename?: 'DispatchedHistoryItem';
  date: Scalars['LocalDate']['output'];
  time: Scalars['Instant']['output'];
  user?: Maybe<TenantMember>;
};

export type Error = {
  message: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type HistoryConnection = {
  __typename?: 'HistoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<HistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WaterStationHistoryItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type HistoryEdge = {
  __typename?: 'HistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: WaterStationHistoryItem;
};

export type IWaterStationGroup = TaggedWaterStationGroup | UntaggedWaterStationGroup;

export type IWaterStationSensor = NoWaterStationSensor | WaterStationEm300Sensor | WaterStationUc500Sensor;

export type InvalidCodeError = Error & {
  __typename?: 'InvalidCodeError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidEmailDomainError = Error & {
  __typename?: 'InvalidEmailDomainError';
  domain: Scalars['String']['output'];
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
  paramName?: Maybe<Scalars['String']['output']>;
};

export type InvalidEmailError = Error & {
  __typename?: 'InvalidEmailError';
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type InvalidRoleIdError = Error & {
  __typename?: 'InvalidRoleIdError';
  id: Scalars['UUID']['output'];
  message: Scalars['String']['output'];
  paramName?: Maybe<Scalars['String']['output']>;
};

export type InvalidSerialError = Error & {
  __typename?: 'InvalidSerialError';
  message: Scalars['String']['output'];
  serial: Scalars['String']['output'];
};

export type InviteForExistingMemberError = Error & {
  __typename?: 'InviteForExistingMemberError';
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
  paramName?: Maybe<Scalars['String']['output']>;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type KnownTag = {
  __typename?: 'KnownTag';
  id: Scalars['ID']['output'];
  instances: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type LastReading = {
  __typename?: 'LastReading';
  litres?: Maybe<Scalars['Float']['output']>;
  pulses: Scalars['Int']['output'];
  time: Scalars['Instant']['output'];
};

export type LocationChangedHistoryItem = WaterStationHistoryItem & {
  __typename?: 'LocationChangedHistoryItem';
  location?: Maybe<Coordinates>;
  time: Scalars['Instant']['output'];
  user?: Maybe<TenantMember>;
};

export type MissingGlobalWaterStationPermissionError = Error & {
  __typename?: 'MissingGlobalWaterStationPermissionError';
  message: Scalars['String']['output'];
  permission: WaterStationPermission;
};

export type MissingTenantPermissionError = Error & {
  __typename?: 'MissingTenantPermissionError';
  message: Scalars['String']['output'];
  permission: TenantPermission;
};

export type MissingWaterStationPermissionError = Error & {
  __typename?: 'MissingWaterStationPermissionError';
  message: Scalars['String']['output'];
  permission: WaterStationPermission;
  waterStationId: Scalars['UUID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activationCodeCreate: ActivationCodeCreatePayload;
  tenantAcceptInvite: TenantAcceptInvitePayload;
  tenantAssignRole: TenantAssignRolePayload;
  /** Consumes an activation code for a tenant. */
  tenantConsumeActivationCode: TenantConsumeActivationCodePayload;
  tenantCreateInvite: TenantCreateInvitePayload;
  tenantCreateOrganisation: TenantCreateOrganisationPayload;
  tenantCreateRole: TenantCreateRolePayload;
  tenantEdit: TenantEditPayload;
  tenantEditRole: TenantEditRolePayload;
  tenantRemoveMember: TenantRemoveMemberPayload;
  tenantRevokeRole: TenantRevokeRolePayload;
  waterStationAddReadingLitres: WaterStationAddReadingLitresPayload;
  waterStationAddReadingPulses: WaterStationAddReadingPulsesPayload;
  waterStationEditDetail: WaterStationEditDetailPayload;
};


export type MutationActivationCodeCreateArgs = {
  input: ActivationCodeCreateInput;
};


export type MutationTenantAcceptInviteArgs = {
  input: TenantAcceptInviteInput;
};


export type MutationTenantAssignRoleArgs = {
  input: TenantAssignRoleInput;
};


export type MutationTenantConsumeActivationCodeArgs = {
  input: TenantConsumeActivationCodeInput;
};


export type MutationTenantCreateInviteArgs = {
  input: TenantCreateInviteInput;
};


export type MutationTenantCreateOrganisationArgs = {
  input: TenantCreateOrganisationInput;
};


export type MutationTenantCreateRoleArgs = {
  input: TenantCreateRoleInput;
};


export type MutationTenantEditArgs = {
  input: TenantEditInput;
};


export type MutationTenantEditRoleArgs = {
  input: TenantEditRoleInput;
};


export type MutationTenantRemoveMemberArgs = {
  input: TenantRemoveMemberInput;
};


export type MutationTenantRevokeRoleArgs = {
  input: TenantRevokeRoleInput;
};


export type MutationWaterStationAddReadingLitresArgs = {
  input: WaterStationAddReadingLitresInput;
};


export type MutationWaterStationAddReadingPulsesArgs = {
  input: WaterStationAddReadingPulsesInput;
};


export type MutationWaterStationEditDetailArgs = {
  input: WaterStationEditDetailInput;
};

export type NoSensorError = Error & {
  __typename?: 'NoSensorError';
  message: Scalars['String']['output'];
};

export type NoWaterStationSensor = {
  __typename?: 'NoWaterStationSensor';
  lastRecording?: Maybe<Scalars['Instant']['output']>;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID']['output'];
};

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  id: Scalars['UUID']['output'];
  message: Scalars['String']['output'];
};

export type OverTenantLimitError = Error & {
  __typename?: 'OverTenantLimitError';
  message: Scalars['String']['output'];
};

export type OwnedUnitError = Error & {
  __typename?: 'OwnedUnitError';
  message: Scalars['String']['output'];
  owner: Scalars['ID']['output'];
  serial: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Plan = {
  __typename?: 'Plan';
  description?: Maybe<Scalars['String']['output']>;
  features: Array<TenantFeature>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  activationCodeById?: Maybe<ActivationCode>;
  knownTags: Array<KnownTag>;
  /** Current User Information */
  me: TenantMember;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  tenantById?: Maybe<Tenant>;
  tenantInvite?: Maybe<TenantInvite>;
  tenants?: Maybe<TenantsConnection>;
  waterStationById?: Maybe<WaterStation>;
  waterStations?: Maybe<WaterStationsConnection>;
  waterStationsByTag?: Maybe<WaterStationsByTagConnection>;
};


export type QueryActivationCodeByIdArgs = {
  code: Scalars['String']['input'];
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryTenantByIdArgs = {
  tenantId: Scalars['ID']['input'];
};


export type QueryTenantInviteArgs = {
  joinCode: Scalars['String']['input'];
};


export type QueryTenantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<TenantSortInput>>;
  where?: InputMaybe<TenantFilterInput>;
};


export type QueryWaterStationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWaterStationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WaterStationProjectionSortInput>>;
  where?: InputMaybe<WaterStationProjectionFilterInput>;
};


export type QueryWaterStationsByTagArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tag: Scalars['String']['input'];
};

export type RenamedHistoryItem = WaterStationHistoryItem & {
  __typename?: 'RenamedHistoryItem';
  name?: Maybe<Scalars['String']['output']>;
  time: Scalars['Instant']['output'];
  user?: Maybe<TenantMember>;
};

export type Role = {
  __typename?: 'Role';
  assignees: Array<RoleAssignment>;
  /** Whether the current use can assign this role to a user. */
  canAssign: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  globalWaterStationPermissions: Array<WaterStationPermission>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tenantPermissions: Array<TenantPermission>;
  waterStationPermissions: Array<WaterStationAccessItem>;
};

export type RoleAssignment = Node & {
  __typename?: 'RoleAssignment';
  id: Scalars['ID']['output'];
  role: Role;
  user: TenantMember;
};

export type RoleNameTakenError = Error & {
  __typename?: 'RoleNameTakenError';
  message: Scalars['String']['output'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Tag = {
  __typename?: 'Tag';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TaggedWaterStationGroup = {
  __typename?: 'TaggedWaterStationGroup';
  tag: Scalars['String']['output'];
  waterStations?: Maybe<WaterStationsConnection>;
};


export type TaggedWaterStationGroupWaterStationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WaterStationProjectionSortInput>>;
  where?: InputMaybe<WaterStationProjectionFilterInput>;
};

export type TagsAddedHistoryItem = WaterStationHistoryItem & {
  __typename?: 'TagsAddedHistoryItem';
  items: Array<KeyValuePairOfStringAndString>;
  time: Scalars['Instant']['output'];
  user?: Maybe<TenantMember>;
};

export type TagsRemovedHistoryItem = WaterStationHistoryItem & {
  __typename?: 'TagsRemovedHistoryItem';
  items: Array<KeyValuePairOfStringAndString>;
  time: Scalars['Instant']['output'];
  user?: Maybe<TenantMember>;
};

export type Tenant = Node & {
  __typename?: 'Tenant';
  emailDomain?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Members of this Tenant. */
  members?: Maybe<Array<TenantMember>>;
  name: Scalars['String']['output'];
  /** Role this Tenant. */
  role?: Maybe<Role>;
  /** Roles for this Tenant. */
  roles: Array<Role>;
  /** Aggregate of all roles for the current tenant. */
  userAccess: UserAccess;
  /** Get all water stations that are owned by this tenant. */
  waterStations?: Maybe<WaterStationsConnection>;
};


export type TenantMembersArgs = {
  order?: InputMaybe<Array<UserProfileSortInput>>;
};


export type TenantRoleArgs = {
  roleId: Scalars['ID']['input'];
};


export type TenantWaterStationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WaterStationProjectionSortInput>>;
  where?: InputMaybe<WaterStationProjectionFilterInput>;
};

export type TenantAcceptInviteError = InvalidCodeError | InvalidEmailError;

export type TenantAcceptInviteInput = {
  code: Scalars['String']['input'];
};

export type TenantAcceptInvitePayload = {
  __typename?: 'TenantAcceptInvitePayload';
  errors?: Maybe<Array<TenantAcceptInviteError>>;
  tenant?: Maybe<Tenant>;
};

export type TenantAssignRoleError = MissingGlobalWaterStationPermissionError | MissingTenantPermissionError | MissingWaterStationPermissionError | NotFoundError;

export type TenantAssignRoleInput = {
  roleId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type TenantAssignRolePayload = {
  __typename?: 'TenantAssignRolePayload';
  errors?: Maybe<Array<TenantAssignRoleError>>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export type TenantConsumeActivationCodeError = ActivationCodeNotFoundError;

export type TenantConsumeActivationCodeInput = {
  /** Activation Code To Consume. */
  code: Scalars['String']['input'];
  /** Tenant To Assign Activation Code To. */
  tenantId: Scalars['ID']['input'];
};

export type TenantConsumeActivationCodePayload = {
  __typename?: 'TenantConsumeActivationCodePayload';
  errors?: Maybe<Array<TenantConsumeActivationCodeError>>;
  unitIds?: Maybe<Array<Scalars['UUID']['output']>>;
};

export type TenantCreateInviteError = InvalidEmailDomainError | InvalidRoleIdError | InviteForExistingMemberError | NotFoundError | UserMissingPermissionsError;

export type TenantCreateInviteInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['ID']['input']>;
  tenantId: Scalars['ID']['input'];
};

export type TenantCreateInvitePayload = {
  __typename?: 'TenantCreateInvitePayload';
  code?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<TenantCreateInviteError>>;
  outcome?: Maybe<TenantInviteOutcome>;
};

export type TenantCreateOrganisationError = OverTenantLimitError | TenantNameTakenError;

export type TenantCreateOrganisationInput = {
  /** Whether to limit user emails to this users domain. */
  limitEmailDomain: Scalars['Boolean']['input'];
  /** Full Display Name of the Tenant. */
  name: Scalars['String']['input'];
};

export type TenantCreateOrganisationPayload = {
  __typename?: 'TenantCreateOrganisationPayload';
  errors?: Maybe<Array<TenantCreateOrganisationError>>;
  tenant?: Maybe<Tenant>;
};

export type TenantCreateRoleError = RoleNameTakenError;

export type TenantCreateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  globalWaterStationPermissions: Array<WaterStationPermission>;
  individualWaterStationPermissions: Array<WaterStationAccessItemInput>;
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
  tenantPermissions: Array<TenantPermission>;
};

export type TenantCreateRolePayload = {
  __typename?: 'TenantCreateRolePayload';
  errors?: Maybe<Array<TenantCreateRoleError>>;
  role?: Maybe<Role>;
};

export type TenantEditError = TenantNameTakenError;

export type TenantEditInput = {
  emailDomain?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};

export type TenantEditPayload = {
  __typename?: 'TenantEditPayload';
  errors?: Maybe<Array<TenantEditError>>;
  tenant?: Maybe<Tenant>;
};

export type TenantEditRoleError = NotFoundError | RoleNameTakenError;

export type TenantEditRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  globalWaterStationPermissions: Array<WaterStationPermission>;
  individualWaterStationPermissions: Array<WaterStationAccessItemInput>;
  name: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
  tenantPermissions: Array<TenantPermission>;
};

export type TenantEditRolePayload = {
  __typename?: 'TenantEditRolePayload';
  errors?: Maybe<Array<TenantEditRoleError>>;
  role?: Maybe<Role>;
};

export enum TenantFeature {
  ChangePlan = 'CHANGE_PLAN',
  ManageMembers = 'MANAGE_MEMBERS',
  ManageWaterStations = 'MANAGE_WATER_STATIONS',
  ReadAssetLifecycleInformation = 'READ_ASSET_LIFECYCLE_INFORMATION',
  ReadBasicInvoiceInformation = 'READ_BASIC_INVOICE_INFORMATION',
  ReadWaterStations = 'READ_WATER_STATIONS'
}

export type TenantFilterInput = {
  and?: InputMaybe<Array<TenantFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TenantFilterInput>>;
};

/** Invite To a Tenant */
export type TenantInvite = {
  __typename?: 'TenantInvite';
  /** Whether the current user can consume the invite */
  canConsume: Scalars['Boolean']['output'];
  /** Unique Invite Code of the Invite */
  code: Scalars['ID']['output'];
  /** Tenant To Access */
  tenantId: Scalars['ID']['output'];
  /** Name of the Tenant. */
  tenantName: Scalars['String']['output'];
};

export enum TenantInviteOutcome {
  /** Activation link will be sent to tenant. */
  EmailSent = 'EMAIL_SENT',
  /** User with email address exists and was added to the tenant. */
  ExistingUserAdded = 'EXISTING_USER_ADDED',
  /** Code To be manually sent. */
  SendRequired = 'SEND_REQUIRED'
}

/** A member of a tenant */
export type TenantMember = {
  __typename?: 'TenantMember';
  /** Members Avatar Image Url */
  avatar: Scalars['String']['output'];
  /** Member display name */
  displayName: Scalars['String']['output'];
  /** Member email address */
  email: Scalars['String']['output'];
  /** Members User Id */
  id: Scalars['ID']['output'];
  roles: Array<Role>;
};

export type TenantNameTakenError = Error & {
  __typename?: 'TenantNameTakenError';
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum TenantPermission {
  All = 'ALL',
  Edit = 'EDIT',
  MembersEdit = 'MEMBERS_EDIT',
  MembersRead = 'MEMBERS_READ',
  None = 'NONE',
  Read = 'READ',
  WaterStationManage = 'WATER_STATION_MANAGE'
}

export type TenantRemoveMemberInput = {
  tenantId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};

export type TenantRemoveMemberPayload = {
  __typename?: 'TenantRemoveMemberPayload';
  success: Scalars['Boolean']['output'];
};

export type TenantRevokeRoleError = AtLeastOneSuperUserRequiredError;

export type TenantRevokeRoleInput = {
  assignmentId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
};

export type TenantRevokeRolePayload = {
  __typename?: 'TenantRevokeRolePayload';
  deletedRoleId?: Maybe<Scalars['ID']['output']>;
  errors?: Maybe<Array<TenantRevokeRoleError>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TenantSortInput = {
  name?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TenantsConnection = {
  __typename?: 'TenantsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TenantsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Tenant>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TenantsEdge = {
  __typename?: 'TenantsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Tenant;
};

export type TrackerLitresAheadError = Error & {
  __typename?: 'TrackerLitresAheadError';
  last: Scalars['Float']['output'];
  message: Scalars['String']['output'];
  provided: Scalars['Float']['output'];
};

export type TrackerTimeAheadError = Error & {
  __typename?: 'TrackerTimeAheadError';
  lastReading: Scalars['Instant']['output'];
  message: Scalars['String']['output'];
  timeProvided: Scalars['Instant']['output'];
};

export type UntaggedWaterStationGroup = {
  __typename?: 'UntaggedWaterStationGroup';
  waterStations?: Maybe<WaterStationsConnection>;
};


export type UntaggedWaterStationGroupWaterStationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WaterStationProjectionSortInput>>;
  where?: InputMaybe<WaterStationProjectionFilterInput>;
};

export type UserAccess = {
  __typename?: 'UserAccess';
  globalWaterStationPermissions: Array<WaterStationPermission>;
  tenantPermissions: Array<TenantPermission>;
  waterStationPermissions: Array<WaterStationAccessItem>;
};

export type UserMissingPermissionsError = Error & {
  __typename?: 'UserMissingPermissionsError';
  message: Scalars['String']['output'];
  paramName?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['ID']['output'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatar: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type UserProfileSortInput = {
  displayName?: InputMaybe<SortEnumType>;
};

/** Water Station */
export type WaterStation = Node & {
  __typename?: 'WaterStation';
  dispatchDate?: Maybe<Scalars['LocalDate']['output']>;
  history?: Maybe<HistoryConnection>;
  id: Scalars['ID']['output'];
  /** Image URL of the water station. */
  imageHref?: Maybe<Scalars['String']['output']>;
  /** Location of the water station. */
  location?: Maybe<Coordinates>;
  /** Model Number of the water station. */
  model: Scalars['String']['output'];
  /** Name of the water station, will default to the serial number. */
  name: Scalars['String']['output'];
  /** The permissions the current user has for this unit. */
  permissions: Array<WaterStationPermission>;
  /** Sensor Information for the Water Station. */
  sensor: IWaterStationSensor;
  /** Serial number of the water station. */
  serial: Scalars['String']['output'];
  /** User editable tags for the water station. */
  tags: Array<Tag>;
  usage?: Maybe<WaterStationUsage>;
};


/** Water Station */
export type WaterStationHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WaterStationAccessItem = {
  __typename?: 'WaterStationAccessItem';
  permissions: Array<WaterStationPermission>;
  unitId: Scalars['ID']['output'];
  waterStation: WaterStation;
};

export type WaterStationAccessItemInput = {
  permissions: Array<WaterStationPermission>;
  unitId: Scalars['ID']['input'];
};

export type WaterStationAddReadingLitresError = NotFoundError | TrackerLitresAheadError | TrackerTimeAheadError;

export type WaterStationAddReadingLitresInput = {
  allowPast: Scalars['Boolean']['input'];
  allowReset: Scalars['Boolean']['input'];
  liters: Scalars['Float']['input'];
  time: Scalars['Instant']['input'];
  unitId: Scalars['UUID']['input'];
};

export type WaterStationAddReadingLitresPayload = {
  __typename?: 'WaterStationAddReadingLitresPayload';
  errors?: Maybe<Array<WaterStationAddReadingLitresError>>;
  waterStation?: Maybe<WaterStation>;
};

export type WaterStationAddReadingPulsesError = NoSensorError | NotFoundError | TrackerLitresAheadError | TrackerTimeAheadError;

export type WaterStationAddReadingPulsesInput = {
  allowPast: Scalars['Boolean']['input'];
  allowReset: Scalars['Boolean']['input'];
  pulses: Scalars['Int']['input'];
  time: Scalars['Instant']['input'];
  unitId: Scalars['UUID']['input'];
};

export type WaterStationAddReadingPulsesPayload = {
  __typename?: 'WaterStationAddReadingPulsesPayload';
  errors?: Maybe<Array<WaterStationAddReadingPulsesError>>;
  waterStation?: Maybe<WaterStation>;
};

export type WaterStationEm300Sensor = {
  __typename?: 'WaterStationEM300Sensor';
  deviceEuid: Scalars['String']['output'];
  lastReading?: Maybe<LastReading>;
};

export type WaterStationEditDetailError = NotFoundError;

export type WaterStationEditDetailInput = {
  coordinates?: InputMaybe<CoordinatesInput>;
  name: Scalars['String']['input'];
  tags: Array<KeyValuePairOfStringAndStringInput>;
  unitId: Scalars['ID']['input'];
};

export type WaterStationEditDetailPayload = {
  __typename?: 'WaterStationEditDetailPayload';
  errors?: Maybe<Array<WaterStationEditDetailError>>;
  waterStation?: Maybe<WaterStation>;
};

export type WaterStationHistoryItem = {
  time: Scalars['Instant']['output'];
  user?: Maybe<TenantMember>;
};

export enum WaterStationPermission {
  All = 'ALL',
  Edit = 'EDIT',
  None = 'NONE',
  Read = 'READ',
  ReadingEntry = 'READING_ENTRY',
  ReadHistory = 'READ_HISTORY'
}

export type WaterStationProjectionFilterInput = {
  and?: InputMaybe<Array<WaterStationProjectionFilterInput>>;
  model?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WaterStationProjectionFilterInput>>;
  serial?: InputMaybe<StringOperationFilterInput>;
};

export type WaterStationProjectionSortInput = {
  model?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
};

export type WaterStationUc500Sensor = {
  __typename?: 'WaterStationUC500Sensor';
  conversionFactor: Scalars['Int']['output'];
  deviceEuid: Scalars['String']['output'];
  lastReading?: Maybe<LastReading>;
};

export type WaterStationUsage = {
  __typename?: 'WaterStationUsage';
  allTime: Scalars['Float']['output'];
  last?: Maybe<Scalars['DateTime']['output']>;
  thisMonth: Scalars['Float']['output'];
  thisWeek: Scalars['Float']['output'];
  thisYear: Scalars['Float']['output'];
  today: Scalars['Float']['output'];
};

export type WaterStationUsageSummary = {
  __typename?: 'WaterStationUsageSummary';
  allTime: Scalars['Float']['output'];
  thisMonth: Scalars['Float']['output'];
  thisWeek: Scalars['Float']['output'];
  thisYear: Scalars['Float']['output'];
  today: Scalars['Float']['output'];
};

/** A connection to a list of items. */
export type WaterStationsByTagConnection = {
  __typename?: 'WaterStationsByTagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WaterStationsByTagEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<IWaterStationGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WaterStationsByTagEdge = {
  __typename?: 'WaterStationsByTagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IWaterStationGroup;
};

/** A connection to a list of items. */
export type WaterStationsConnection = {
  __typename?: 'WaterStationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WaterStationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WaterStation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
  usageSummary?: Maybe<WaterStationUsageSummary>;
};

/** An edge in a connection. */
export type WaterStationsEdge = {
  __typename?: 'WaterStationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: WaterStation;
};

export type TenantLayoutFragment = { __typename?: 'Tenant', id: string, name: string };

export type InvitePageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvitePageQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, members?: Array<{ __typename?: 'TenantMember', email: string }> | null, roles: Array<{ __typename?: 'Role', id: string, name: string, canAssign: boolean }> } | null };

export type CreateInviteMutationVariables = Exact<{
  input: TenantCreateInviteInput;
}>;


export type CreateInviteMutation = { __typename?: 'Mutation', tenantCreateInvite: { __typename?: 'TenantCreateInvitePayload', code?: string | null, outcome?: TenantInviteOutcome | null, errors?: Array<{ __typename: 'InvalidEmailDomainError' } | { __typename: 'InvalidRoleIdError' } | { __typename: 'InviteForExistingMemberError' } | { __typename: 'NotFoundError' } | { __typename: 'UserMissingPermissionsError' }> | null } };

export type TenantPageQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;


export type TenantPageQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null, userAccess: { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission> }, members?: Array<{ __typename?: 'TenantMember', id: string, displayName: string, avatar: string, email: string, roles: Array<{ __typename?: 'Role', id: string, name: string }> }> | null, roles: Array<{ __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission> }>, assignees: Array<{ __typename?: 'RoleAssignment', user: { __typename?: 'TenantMember', id: string, avatar: string, displayName: string } }> }> } | null };

export type EditRolePageQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
}>;


export type EditRolePageQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, userAccess: { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission> }> }, waterStations?: { __typename?: 'WaterStationsConnection', nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, serial: string }> | null } | null, role?: { __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission>, waterStation: { __typename?: 'WaterStation', id: string, name: string } }> } | null } | null };

export type EditRoleMutationVariables = Exact<{
  input: TenantEditRoleInput;
}>;


export type EditRoleMutation = { __typename?: 'Mutation', tenantEditRole: { __typename?: 'TenantEditRolePayload', role?: { __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission>, waterStation: { __typename?: 'WaterStation', id: string, name: string } }> } | null, errors?: Array<{ __typename?: 'NotFoundError', code: 'NotFoundError' } | { __typename?: 'RoleNameTakenError', code: 'RoleNameTakenError' }> | null } };

export type GetRoleQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
}>;


export type GetRoleQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, members?: Array<{ __typename?: 'TenantMember', id: string, displayName: string, email: string }> | null, role?: { __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, assignees: Array<{ __typename?: 'RoleAssignment', id: string, user: { __typename?: 'TenantMember', email: string, id: string, avatar: string, displayName: string } }>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission>, waterStation: { __typename?: 'WaterStation', id: string, name: string } }> } | null } | null };

export type RevokeRoleMutationVariables = Exact<{
  input: TenantRevokeRoleInput;
}>;


export type RevokeRoleMutation = { __typename?: 'Mutation', tenantRevokeRole: { __typename?: 'TenantRevokeRolePayload', id?: string | null, errors?: Array<{ __typename?: 'AtLeastOneSuperUserRequiredError', code: 'AtLeastOneSuperUserRequiredError' }> | null } };

export type AssignRoleMutationVariables = Exact<{
  input: TenantAssignRoleInput;
}>;


export type AssignRoleMutation = { __typename?: 'Mutation', tenantAssignRole: { __typename?: 'TenantAssignRolePayload', roleAssignment?: { __typename?: 'RoleAssignment', id: string, user: { __typename?: 'TenantMember', email: string, id: string, avatar: string, displayName: string }, role: { __typename?: 'Role', id: string, name: string, assignees: Array<{ __typename?: 'RoleAssignment', id: string, user: { __typename?: 'TenantMember', email: string, id: string, avatar: string, displayName: string } }> } } | null, errors?: Array<{ __typename?: 'MissingGlobalWaterStationPermissionError', code: 'MissingGlobalWaterStationPermissionError' } | { __typename?: 'MissingTenantPermissionError', code: 'MissingTenantPermissionError' } | { __typename?: 'MissingWaterStationPermissionError', code: 'MissingWaterStationPermissionError' } | { __typename?: 'NotFoundError', code: 'NotFoundError' }> | null } };

export type UserAccessFragment = { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission> }> };

export type NewRoleWaterStationsFragment = { __typename?: 'WaterStation', id: string, name: string, serial: string };

export type NewRolePageQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;


export type NewRolePageQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, userAccess: { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission> }> }, waterStations?: { __typename?: 'WaterStationsConnection', nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, serial: string }> | null } | null, roles: Array<{ __typename?: 'Role', id: string, name: string }> } | null };

export type CreateRoleMutationVariables = Exact<{
  input: TenantCreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', tenantCreateRole: { __typename?: 'TenantCreateRolePayload', role?: { __typename?: 'Role', id: string, name: string } | null, errors?: Array<{ __typename?: 'RoleNameTakenError', code: 'RoleNameTakenError' }> | null } };

export type RoleListItemFragment = { __typename?: 'Role', name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', permissions: Array<WaterStationPermission>, waterStation: { __typename?: 'WaterStation', name: string } }> };

export type RolesPageQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;


export type RolesPageQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, userAccess: { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission> }, roles: Array<{ __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', permissions: Array<WaterStationPermission>, waterStation: { __typename?: 'WaterStation', name: string } }> }> } | null };

export type GetTenantSettingsQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;


export type GetTenantSettingsQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, emailDomain?: string | null, userAccess: { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission> } } | null };

export type TenantSettingsMutationVariables = Exact<{
  input: TenantEditInput;
}>;


export type TenantSettingsMutation = { __typename?: 'Mutation', tenantEdit: { __typename?: 'TenantEditPayload', tenant?: { __typename?: 'Tenant', id: string, name: string, emailDomain?: string | null } | null, errors?: Array<{ __typename?: 'TenantNameTakenError', code: 'TenantNameTakenError' }> | null } };

export type WaterStationDataTableByTenantQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<WaterStationProjectionFilterInput>;
  order?: InputMaybe<Array<WaterStationProjectionSortInput> | WaterStationProjectionSortInput>;
}>;


export type WaterStationDataTableByTenantQuery = { __typename?: 'Query', tenantById?: { __typename?: 'Tenant', id: string, name: string, waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null } | null };

export type OrganisationJoinQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type OrganisationJoinQuery = { __typename?: 'Query', tenantInvite?: { __typename?: 'TenantInvite', code: string, tenantId: string, tenantName: string, canConsume: boolean } | null };

export type AcceptInviteMutationVariables = Exact<{
  input: TenantAcceptInviteInput;
}>;


export type AcceptInviteMutation = { __typename?: 'Mutation', tenantAcceptInvite: { __typename?: 'TenantAcceptInvitePayload', tenant?: { __typename?: 'Tenant', id: string, name: string } | null } };

export type NewTenantPageQueryVariables = Exact<{ [key: string]: never; }>;


export type NewTenantPageQuery = { __typename?: 'Query', me: { __typename?: 'TenantMember', id: string, email: string } };

export type CreateTenantMutationVariables = Exact<{
  input: TenantCreateOrganisationInput;
}>;


export type CreateTenantMutation = { __typename?: 'Mutation', tenantCreateOrganisation: { __typename?: 'TenantCreateOrganisationPayload', tenant?: { __typename?: 'Tenant', id: string, name: string } | null, errors?: Array<{ __typename?: 'OverTenantLimitError', code: 'OverTenantLimitError' } | { __typename?: 'TenantNameTakenError', code: 'TenantNameTakenError' }> | null } };

export type GetTenantsDetailShortFragment = { __typename?: 'Tenant', id: string, name: string, waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, nodes?: Array<{ __typename?: 'WaterStation', id: string, serial: string, imageHref?: string | null }> | null } | null, members?: Array<{ __typename?: 'TenantMember', id: string, displayName: string, avatar: string }> | null };

export type GetTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantsQuery = { __typename?: 'Query', tenants?: { __typename?: 'TenantsConnection', nodes?: Array<{ __typename?: 'Tenant', id: string, name: string, waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, nodes?: Array<{ __typename?: 'WaterStation', id: string, serial: string, imageHref?: string | null }> | null } | null, members?: Array<{ __typename?: 'TenantMember', id: string, displayName: string, avatar: string }> | null }> | null } | null };

export type GetKnownTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKnownTagsQuery = { __typename?: 'Query', knownTags: Array<{ __typename?: 'KnownTag', id: string, key: string }> };

type HistoryNode_DispatchedHistoryItem_Fragment = { __typename?: 'DispatchedHistoryItem', date: any, time: any, code: 'DispatchedHistoryItem', user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null };

type HistoryNode_LocationChangedHistoryItem_Fragment = { __typename?: 'LocationChangedHistoryItem', time: any, code: 'LocationChangedHistoryItem', location?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null, user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null };

type HistoryNode_RenamedHistoryItem_Fragment = { __typename?: 'RenamedHistoryItem', name?: string | null, time: any, code: 'RenamedHistoryItem', user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null };

type HistoryNode_TagsAddedHistoryItem_Fragment = { __typename?: 'TagsAddedHistoryItem', time: any, code: 'TagsAddedHistoryItem', items: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }>, user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null };

type HistoryNode_TagsRemovedHistoryItem_Fragment = { __typename?: 'TagsRemovedHistoryItem', time: any, code: 'TagsRemovedHistoryItem', items: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }>, user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null };

export type HistoryNodeFragment = HistoryNode_DispatchedHistoryItem_Fragment | HistoryNode_LocationChangedHistoryItem_Fragment | HistoryNode_RenamedHistoryItem_Fragment | HistoryNode_TagsAddedHistoryItem_Fragment | HistoryNode_TagsRemovedHistoryItem_Fragment;

export type WaterStationHistoryPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WaterStationHistoryPageQuery = { __typename?: 'Query', waterStationById?: { __typename?: 'WaterStation', id: string, name: string, serial: string, permissions: Array<WaterStationPermission>, history?: { __typename?: 'HistoryConnection', nodes?: Array<{ __typename?: 'DispatchedHistoryItem', date: any, time: any, code: 'DispatchedHistoryItem', user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null } | { __typename?: 'LocationChangedHistoryItem', time: any, code: 'LocationChangedHistoryItem', location?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null, user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null } | { __typename?: 'RenamedHistoryItem', name?: string | null, time: any, code: 'RenamedHistoryItem', user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null } | { __typename?: 'TagsAddedHistoryItem', time: any, code: 'TagsAddedHistoryItem', items: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }>, user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null } | { __typename?: 'TagsRemovedHistoryItem', time: any, code: 'TagsRemovedHistoryItem', items: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }>, user?: { __typename?: 'TenantMember', avatar: string, displayName: string, email: string } | null }> | null } | null } | null };

export type WaterStationLayoutFragment = { __typename?: 'WaterStation', id: string, name: string, serial: string, permissions: Array<WaterStationPermission> };

export type GetWaterStationByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetWaterStationByIdQuery = { __typename?: 'Query', waterStationById?: { __typename?: 'WaterStation', id: string, serial: string, name: string, model: string, dispatchDate?: any | null, imageHref?: string | null, permissions: Array<WaterStationPermission>, usage?: { __typename?: 'WaterStationUsage', allTime: number, today: number, thisWeek: number, thisMonth: number } | null, tags: Array<{ __typename?: 'Tag', key: string, value: string }>, location?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null } | null };

export type NoWaterStationSensorFormFragment = { __typename: 'NoWaterStationSensor', lastRecording?: any | null };

export type WaterStationUc500SensorFormFragment = { __typename: 'WaterStationUC500Sensor', conversionFactor: number, lastReading?: { __typename?: 'LastReading', litres?: number | null, pulses: number, time: any } | null };

export type WaterStationEm300SensorFormFragment = { __typename: 'WaterStationEM300Sensor', lastReading?: { __typename?: 'LastReading', litres?: number | null, pulses: number, time: any } | null };

export type WaterStationReadingEntryPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WaterStationReadingEntryPageQuery = { __typename?: 'Query', waterStationById?: { __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, permissions: Array<WaterStationPermission>, sensor: { __typename: 'NoWaterStationSensor', lastRecording?: any | null } | { __typename: 'WaterStationEM300Sensor', lastReading?: { __typename?: 'LastReading', litres?: number | null, pulses: number, time: any } | null } | { __typename: 'WaterStationUC500Sensor', conversionFactor: number, lastReading?: { __typename?: 'LastReading', litres?: number | null, pulses: number, time: any } | null } } | null };

export type AddReadingLitresMutationVariables = Exact<{
  input: WaterStationAddReadingLitresInput;
}>;


export type AddReadingLitresMutation = { __typename?: 'Mutation', waterStationAddReadingLitres: { __typename?: 'WaterStationAddReadingLitresPayload', errors?: Array<{ __typename: 'NotFoundError' } | { __typename: 'TrackerLitresAheadError' } | { __typename: 'TrackerTimeAheadError' }> | null, waterStation?: { __typename?: 'WaterStation', usage?: { __typename?: 'WaterStationUsage', allTime: number } | null } | null } };

export type AddReadingPulsesMutationVariables = Exact<{
  input: WaterStationAddReadingPulsesInput;
}>;


export type AddReadingPulsesMutation = { __typename?: 'Mutation', waterStationAddReadingPulses: { __typename?: 'WaterStationAddReadingPulsesPayload', errors?: Array<{ __typename: 'NoSensorError' } | { __typename: 'NotFoundError' } | { __typename: 'TrackerLitresAheadError' } | { __typename: 'TrackerTimeAheadError' }> | null, waterStation?: { __typename?: 'WaterStation', usage?: { __typename?: 'WaterStationUsage', allTime: number } | null } | null } };

export type WaterStationsSettingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WaterStationsSettingsQuery = { __typename?: 'Query', knownTags: Array<{ __typename?: 'KnownTag', key: string, values: Array<string>, instances: number }>, waterStationById?: { __typename?: 'WaterStation', id: string, serial: string, name: string, imageHref?: string | null, permissions: Array<WaterStationPermission>, location?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null, tags: Array<{ __typename?: 'Tag', value: string, key: string }> } | null };

export type EditWaterStationMutationVariables = Exact<{
  input: WaterStationEditDetailInput;
}>;


export type EditWaterStationMutation = { __typename?: 'Mutation', waterStationEditDetail: { __typename?: 'WaterStationEditDetailPayload', waterStation?: { __typename?: 'WaterStation', id: string, name: string, location?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null, tags: Array<{ __typename?: 'Tag', key: string, value: string }> } | null, errors?: Array<{ __typename: 'NotFoundError' }> | null } };

export type GetActivationCodeItemFragment = { __typename?: 'ActivationCodeItem', serial: string, model: string };

export type GetActivationCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type GetActivationCodeQuery = { __typename?: 'Query', activationCodeById?: { __typename?: 'ActivationCode', availableTenants?: { __typename?: 'AvailableTenantsConnection', nodes?: Array<{ __typename?: 'Tenant', id: string, name: string }> | null } | null, items: Array<{ __typename?: 'ActivationCodeItem', serial: string, model: string }> } | null };

export type ConsumeActivationCodeMutationVariables = Exact<{
  input: TenantConsumeActivationCodeInput;
}>;


export type ConsumeActivationCodeMutation = { __typename?: 'Mutation', tenantConsumeActivationCode: { __typename?: 'TenantConsumeActivationCodePayload', unitIds?: Array<any> | null, errors?: Array<{ __typename?: 'ActivationCodeNotFoundError', code: 'ActivationCodeNotFoundError' }> | null } };

export type CreateActivationCodeMutationVariables = Exact<{
  input: ActivationCodeCreateInput;
}>;


export type CreateActivationCodeMutation = { __typename?: 'Mutation', activationCodeCreate: { __typename?: 'ActivationCodeCreatePayload', code?: string | null, errors?: Array<{ __typename?: 'CodeExistsError', serial: string, code: 'CodeExistsError' } | { __typename?: 'InvalidSerialError', serial: string, code: 'InvalidSerialError' } | { __typename?: 'OwnedUnitError', serial: string, code: 'OwnedUnitError' }> | null } };

export type AvatarStackItemFragment = { __typename?: 'TenantMember', id: string, avatar: string, displayName: string };

export type BreadcrumbTenantFragment = { __typename?: 'Tenant', id: string, name: string };

export type BreadcrumbRoleFragment = { __typename?: 'Role', id: string, name: string };

export type BreadcrumbWaterStationFragment = { __typename?: 'WaterStation', id: string, name: string };

export type TenantMembersFragment = { __typename?: 'Tenant', members?: Array<{ __typename?: 'TenantMember', id: string, displayName: string, avatar: string, email: string, roles: Array<{ __typename?: 'Role', id: string, name: string }> }> | null };

export type TenantRoleItemFragment = { __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission> }>, assignees: Array<{ __typename?: 'RoleAssignment', user: { __typename?: 'TenantMember', id: string, avatar: string, displayName: string } }> };

export type TenantRolesFragment = { __typename?: 'Tenant', roles: Array<{ __typename?: 'Role', id: string, name: string, description?: string | null, tenantPermissions: Array<TenantPermission>, globalWaterStationPermissions: Array<WaterStationPermission>, waterStationPermissions: Array<{ __typename?: 'WaterStationAccessItem', unitId: string, permissions: Array<WaterStationPermission> }>, assignees: Array<{ __typename?: 'RoleAssignment', user: { __typename?: 'TenantMember', id: string, avatar: string, displayName: string } }> }> };

export type WaterStationMapViewFragment = { __typename?: 'WaterStation', location?: { __typename?: 'Coordinates', latitude: number, longitude: number } | null };

export type WaterStationDataTableItemFragment = { __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null };

export type WaterStationDataTableFragment = { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null };

export type WaterStationDataTableQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<WaterStationProjectionFilterInput>;
  order?: InputMaybe<Array<WaterStationProjectionSortInput> | WaterStationProjectionSortInput>;
}>;


export type WaterStationDataTableQuery = { __typename?: 'Query', waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null };

export type WaterStationFilterPanelFragment = { __typename?: 'Query', knownTags: Array<{ __typename?: 'KnownTag', key: string }> };

type WaterStationGroupItem_TaggedWaterStationGroup_Fragment = { __typename: 'TaggedWaterStationGroup', tag: string, waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null };

type WaterStationGroupItem_UntaggedWaterStationGroup_Fragment = { __typename: 'UntaggedWaterStationGroup', waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null };

export type WaterStationGroupItemFragment = WaterStationGroupItem_TaggedWaterStationGroup_Fragment | WaterStationGroupItem_UntaggedWaterStationGroup_Fragment;

export type WaterStationGroupedTableQueryVariables = Exact<{
  tag: Scalars['String']['input'];
}>;


export type WaterStationGroupedTableQuery = { __typename?: 'Query', waterStationsByTag?: { __typename?: 'WaterStationsByTagConnection', nodes?: Array<{ __typename: 'TaggedWaterStationGroup', tag: string, waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null } | { __typename: 'UntaggedWaterStationGroup', waterStations?: { __typename?: 'WaterStationsConnection', totalCount: number, usageSummary?: { __typename?: 'WaterStationUsageSummary', allTime: number } | null, nodes?: Array<{ __typename?: 'WaterStation', id: string, name: string, model: string, serial: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', last?: any | null, allTime: number } | null }> | null } | null }> | null } | null };

export type WaterStationListItemFragment = { __typename?: 'WaterStation', id: string, serial: string, name: string, model: string, imageHref?: string | null, usage?: { __typename?: 'WaterStationUsage', allTime: number } | null };

export type TenantPermissionGuardFragment = { __typename?: 'Tenant', id: string, name: string, userAccess: { __typename?: 'UserAccess', tenantPermissions: Array<TenantPermission> } };

export const TenantLayoutFragmentDoc = gql`
    fragment TenantLayout on Tenant {
  id
  name
}
    `;
export const UserAccessFragmentDoc = gql`
    fragment UserAccess on UserAccess {
  tenantPermissions
  globalWaterStationPermissions
  waterStationPermissions {
    unitId
    permissions
  }
}
    `;
export const NewRoleWaterStationsFragmentDoc = gql`
    fragment NewRoleWaterStations on WaterStation {
  id
  name
  serial
}
    `;
export const RoleListItemFragmentDoc = gql`
    fragment RoleListItem on Role {
  name
  description
  tenantPermissions
  globalWaterStationPermissions
  waterStationPermissions {
    waterStation {
      name
    }
    permissions
  }
}
    `;
export const GetTenantsDetailShortFragmentDoc = gql`
    fragment GetTenantsDetailShort on Tenant {
  id
  name
  waterStations(first: 5) {
    totalCount
    nodes {
      id
      serial
      imageHref
    }
  }
  members {
    id
    displayName
    avatar
  }
}
    `;
export const HistoryNodeFragmentDoc = gql`
    fragment HistoryNode on WaterStationHistoryItem {
  code: __typename
  time
  user {
    avatar
    displayName
    email
  }
  ... on DispatchedHistoryItem {
    date
  }
  ... on RenamedHistoryItem {
    name
  }
  ... on TagsAddedHistoryItem {
    items {
      key
      value
    }
  }
  ... on TagsRemovedHistoryItem {
    items {
      key
      value
    }
  }
  ... on LocationChangedHistoryItem {
    location {
      latitude
      longitude
    }
  }
}
    `;
export const WaterStationLayoutFragmentDoc = gql`
    fragment WaterStationLayout on WaterStation {
  id
  name
  serial
  permissions
}
    `;
export const NoWaterStationSensorFormFragmentDoc = gql`
    fragment NoWaterStationSensorForm on NoWaterStationSensor {
  __typename
  lastRecording
}
    `;
export const WaterStationUc500SensorFormFragmentDoc = gql`
    fragment WaterStationUC500SensorForm on WaterStationUC500Sensor {
  __typename
  conversionFactor
  lastReading {
    litres
    pulses
    time
  }
}
    `;
export const WaterStationEm300SensorFormFragmentDoc = gql`
    fragment WaterStationEM300SensorForm on WaterStationEM300Sensor {
  __typename
  lastReading {
    litres
    pulses
    time
  }
}
    `;
export const GetActivationCodeItemFragmentDoc = gql`
    fragment GetActivationCodeItem on ActivationCodeItem {
  serial
  model
}
    `;
export const BreadcrumbTenantFragmentDoc = gql`
    fragment BreadcrumbTenant on Tenant {
  id
  name
}
    `;
export const BreadcrumbRoleFragmentDoc = gql`
    fragment BreadcrumbRole on Role {
  id
  name
}
    `;
export const BreadcrumbWaterStationFragmentDoc = gql`
    fragment BreadcrumbWaterStation on WaterStation {
  id
  name
}
    `;
export const TenantMembersFragmentDoc = gql`
    fragment TenantMembers on Tenant {
  members {
    id
    displayName
    avatar
    email
    roles {
      id
      name
    }
  }
}
    `;
export const AvatarStackItemFragmentDoc = gql`
    fragment AvatarStackItem on TenantMember {
  id
  avatar
  displayName
}
    `;
export const TenantRoleItemFragmentDoc = gql`
    fragment TenantRoleItem on Role {
  id
  name
  description
  tenantPermissions
  globalWaterStationPermissions
  waterStationPermissions {
    unitId
    permissions
  }
  assignees {
    user {
      ...AvatarStackItem
    }
  }
}
    ${AvatarStackItemFragmentDoc}`;
export const TenantRolesFragmentDoc = gql`
    fragment TenantRoles on Tenant {
  roles {
    id
    ...TenantRoleItem
  }
}
    ${TenantRoleItemFragmentDoc}`;
export const WaterStationMapViewFragmentDoc = gql`
    fragment WaterStationMapView on WaterStation {
  location {
    latitude
    longitude
  }
}
    `;
export const WaterStationFilterPanelFragmentDoc = gql`
    fragment WaterStationFilterPanel on Query {
  knownTags {
    key
  }
}
    `;
export const WaterStationDataTableItemFragmentDoc = gql`
    fragment WaterStationDataTableItem on WaterStation {
  id
  name
  model
  serial
  imageHref
  usage {
    last
    allTime
  }
}
    `;
export const WaterStationDataTableFragmentDoc = gql`
    fragment WaterStationDataTable on WaterStationsConnection {
  totalCount
  usageSummary {
    allTime
  }
  nodes {
    ...WaterStationDataTableItem
  }
}
    ${WaterStationDataTableItemFragmentDoc}`;
export const WaterStationGroupItemFragmentDoc = gql`
    fragment WaterStationGroupItem on IWaterStationGroup {
  __typename
  ... on TaggedWaterStationGroup {
    tag
    waterStations(first: 100) {
      ...WaterStationDataTable
    }
  }
  ... on UntaggedWaterStationGroup {
    waterStations(first: 100) {
      ...WaterStationDataTable
    }
  }
}
    ${WaterStationDataTableFragmentDoc}`;
export const WaterStationListItemFragmentDoc = gql`
    fragment WaterStationListItem on WaterStation {
  id
  serial
  name
  model
  imageHref
  usage {
    allTime
  }
}
    `;
export const TenantPermissionGuardFragmentDoc = gql`
    fragment TenantPermissionGuard on Tenant {
  id
  name
  userAccess {
    tenantPermissions
  }
}
    `;
export const InvitePageDocument = gql`
    query InvitePage($id: ID!) {
  tenantById(tenantId: $id) {
    id
    name
    members {
      email
    }
    roles {
      id
      name
      canAssign
    }
  }
}
    `;

/**
 * __useInvitePageQuery__
 *
 * To run a query within a React component, call `useInvitePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvitePageQuery(baseOptions: Apollo.QueryHookOptions<InvitePageQuery, InvitePageQueryVariables> & ({ variables: InvitePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitePageQuery, InvitePageQueryVariables>(InvitePageDocument, options);
      }
export function useInvitePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitePageQuery, InvitePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitePageQuery, InvitePageQueryVariables>(InvitePageDocument, options);
        }
export function useInvitePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvitePageQuery, InvitePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitePageQuery, InvitePageQueryVariables>(InvitePageDocument, options);
        }
export type InvitePageQueryHookResult = ReturnType<typeof useInvitePageQuery>;
export type InvitePageLazyQueryHookResult = ReturnType<typeof useInvitePageLazyQuery>;
export type InvitePageSuspenseQueryHookResult = ReturnType<typeof useInvitePageSuspenseQuery>;
export type InvitePageQueryResult = Apollo.QueryResult<InvitePageQuery, InvitePageQueryVariables>;
export const CreateInviteDocument = gql`
    mutation CreateInvite($input: TenantCreateInviteInput!) {
  tenantCreateInvite(input: $input) {
    code
    outcome
    errors {
      __typename
    }
  }
}
    `;
export type CreateInviteMutationFn = Apollo.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, options);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const TenantPageDocument = gql`
    query TenantPage($tenantId: ID!) {
  tenantById(tenantId: $tenantId) {
    id
    name
    ...TenantPermissionGuard
    ...TenantMembers
    ...TenantRoles
    waterStations(first: 10, order: {name: ASC}) {
      usageSummary {
        allTime
      }
      ...WaterStationDataTable
    }
  }
}
    ${TenantPermissionGuardFragmentDoc}
${TenantMembersFragmentDoc}
${TenantRolesFragmentDoc}
${WaterStationDataTableFragmentDoc}`;

/**
 * __useTenantPageQuery__
 *
 * To run a query within a React component, call `useTenantPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantPageQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantPageQuery(baseOptions: Apollo.QueryHookOptions<TenantPageQuery, TenantPageQueryVariables> & ({ variables: TenantPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantPageQuery, TenantPageQueryVariables>(TenantPageDocument, options);
      }
export function useTenantPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantPageQuery, TenantPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantPageQuery, TenantPageQueryVariables>(TenantPageDocument, options);
        }
export function useTenantPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantPageQuery, TenantPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantPageQuery, TenantPageQueryVariables>(TenantPageDocument, options);
        }
export type TenantPageQueryHookResult = ReturnType<typeof useTenantPageQuery>;
export type TenantPageLazyQueryHookResult = ReturnType<typeof useTenantPageLazyQuery>;
export type TenantPageSuspenseQueryHookResult = ReturnType<typeof useTenantPageSuspenseQuery>;
export type TenantPageQueryResult = Apollo.QueryResult<TenantPageQuery, TenantPageQueryVariables>;
export const EditRolePageDocument = gql`
    query EditRolePage($tenantId: ID!, $roleId: ID!) {
  tenantById(tenantId: $tenantId) {
    id
    name
    userAccess {
      ...UserAccess
    }
    waterStations {
      nodes {
        ...NewRoleWaterStations
      }
    }
    role(roleId: $roleId) {
      id
      name
      description
      tenantPermissions
      globalWaterStationPermissions
      waterStationPermissions {
        unitId
        permissions
        waterStation {
          id
          name
        }
      }
    }
  }
}
    ${UserAccessFragmentDoc}
${NewRoleWaterStationsFragmentDoc}`;

/**
 * __useEditRolePageQuery__
 *
 * To run a query within a React component, call `useEditRolePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditRolePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditRolePageQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useEditRolePageQuery(baseOptions: Apollo.QueryHookOptions<EditRolePageQuery, EditRolePageQueryVariables> & ({ variables: EditRolePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditRolePageQuery, EditRolePageQueryVariables>(EditRolePageDocument, options);
      }
export function useEditRolePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditRolePageQuery, EditRolePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditRolePageQuery, EditRolePageQueryVariables>(EditRolePageDocument, options);
        }
export function useEditRolePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EditRolePageQuery, EditRolePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditRolePageQuery, EditRolePageQueryVariables>(EditRolePageDocument, options);
        }
export type EditRolePageQueryHookResult = ReturnType<typeof useEditRolePageQuery>;
export type EditRolePageLazyQueryHookResult = ReturnType<typeof useEditRolePageLazyQuery>;
export type EditRolePageSuspenseQueryHookResult = ReturnType<typeof useEditRolePageSuspenseQuery>;
export type EditRolePageQueryResult = Apollo.QueryResult<EditRolePageQuery, EditRolePageQueryVariables>;
export const EditRoleDocument = gql`
    mutation EditRole($input: TenantEditRoleInput!) {
  tenantEditRole(input: $input) {
    role {
      id
      name
      description
      tenantPermissions
      waterStationPermissions {
        unitId
        permissions
        waterStation {
          id
          name
        }
      }
      globalWaterStationPermissions
    }
    errors {
      code: __typename
    }
  }
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, options);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const GetRoleDocument = gql`
    query GetRole($tenantId: ID!, $roleId: ID!) {
  tenantById(tenantId: $tenantId) {
    id
    name
    members {
      id
      displayName
      email
    }
    role(roleId: $roleId) {
      id
      name
      description
      assignees {
        id
        user {
          ...AvatarStackItem
          email
        }
      }
      tenantPermissions
      globalWaterStationPermissions
      waterStationPermissions {
        unitId
        permissions
        waterStation {
          id
          name
        }
      }
    }
  }
}
    ${AvatarStackItemFragmentDoc}`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables> & ({ variables: GetRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export function useGetRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleSuspenseQueryHookResult = ReturnType<typeof useGetRoleSuspenseQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const RevokeRoleDocument = gql`
    mutation RevokeRole($input: TenantRevokeRoleInput!) {
  tenantRevokeRole(input: $input) {
    id
    errors {
      code: __typename
    }
  }
}
    `;
export type RevokeRoleMutationFn = Apollo.MutationFunction<RevokeRoleMutation, RevokeRoleMutationVariables>;

/**
 * __useRevokeRoleMutation__
 *
 * To run a mutation, you first call `useRevokeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeRoleMutation, { data, loading, error }] = useRevokeRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeRoleMutation(baseOptions?: Apollo.MutationHookOptions<RevokeRoleMutation, RevokeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeRoleMutation, RevokeRoleMutationVariables>(RevokeRoleDocument, options);
      }
export type RevokeRoleMutationHookResult = ReturnType<typeof useRevokeRoleMutation>;
export type RevokeRoleMutationResult = Apollo.MutationResult<RevokeRoleMutation>;
export type RevokeRoleMutationOptions = Apollo.BaseMutationOptions<RevokeRoleMutation, RevokeRoleMutationVariables>;
export const AssignRoleDocument = gql`
    mutation AssignRole($input: TenantAssignRoleInput!) {
  tenantAssignRole(input: $input) {
    roleAssignment {
      id
      user {
        ...AvatarStackItem
        email
      }
      role {
        id
        name
        assignees {
          id
          user {
            ...AvatarStackItem
            email
          }
        }
      }
    }
    errors {
      code: __typename
    }
  }
}
    ${AvatarStackItemFragmentDoc}`;
export type AssignRoleMutationFn = Apollo.MutationFunction<AssignRoleMutation, AssignRoleMutationVariables>;

/**
 * __useAssignRoleMutation__
 *
 * To run a mutation, you first call `useAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRoleMutation, { data, loading, error }] = useAssignRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignRoleMutation, AssignRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRoleMutation, AssignRoleMutationVariables>(AssignRoleDocument, options);
      }
export type AssignRoleMutationHookResult = ReturnType<typeof useAssignRoleMutation>;
export type AssignRoleMutationResult = Apollo.MutationResult<AssignRoleMutation>;
export type AssignRoleMutationOptions = Apollo.BaseMutationOptions<AssignRoleMutation, AssignRoleMutationVariables>;
export const NewRolePageDocument = gql`
    query NewRolePage($tenantId: ID!) {
  tenantById(tenantId: $tenantId) {
    ...TenantLayout
    userAccess {
      ...UserAccess
    }
    waterStations {
      nodes {
        ...NewRoleWaterStations
      }
    }
    roles {
      id
      name
    }
  }
}
    ${TenantLayoutFragmentDoc}
${UserAccessFragmentDoc}
${NewRoleWaterStationsFragmentDoc}`;

/**
 * __useNewRolePageQuery__
 *
 * To run a query within a React component, call `useNewRolePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewRolePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewRolePageQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useNewRolePageQuery(baseOptions: Apollo.QueryHookOptions<NewRolePageQuery, NewRolePageQueryVariables> & ({ variables: NewRolePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewRolePageQuery, NewRolePageQueryVariables>(NewRolePageDocument, options);
      }
export function useNewRolePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewRolePageQuery, NewRolePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewRolePageQuery, NewRolePageQueryVariables>(NewRolePageDocument, options);
        }
export function useNewRolePageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewRolePageQuery, NewRolePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewRolePageQuery, NewRolePageQueryVariables>(NewRolePageDocument, options);
        }
export type NewRolePageQueryHookResult = ReturnType<typeof useNewRolePageQuery>;
export type NewRolePageLazyQueryHookResult = ReturnType<typeof useNewRolePageLazyQuery>;
export type NewRolePageSuspenseQueryHookResult = ReturnType<typeof useNewRolePageSuspenseQuery>;
export type NewRolePageQueryResult = Apollo.QueryResult<NewRolePageQuery, NewRolePageQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: TenantCreateRoleInput!) {
  tenantCreateRole(input: $input) {
    role {
      id
      name
    }
    errors {
      code: __typename
    }
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const RolesPageDocument = gql`
    query RolesPage($tenantId: ID!) {
  tenantById(tenantId: $tenantId) {
    ...TenantLayout
    ...TenantPermissionGuard
    userAccess {
      tenantPermissions
    }
    roles {
      id
      ...RoleListItem
    }
  }
}
    ${TenantLayoutFragmentDoc}
${TenantPermissionGuardFragmentDoc}
${RoleListItemFragmentDoc}`;

/**
 * __useRolesPageQuery__
 *
 * To run a query within a React component, call `useRolesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesPageQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useRolesPageQuery(baseOptions: Apollo.QueryHookOptions<RolesPageQuery, RolesPageQueryVariables> & ({ variables: RolesPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesPageQuery, RolesPageQueryVariables>(RolesPageDocument, options);
      }
export function useRolesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesPageQuery, RolesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesPageQuery, RolesPageQueryVariables>(RolesPageDocument, options);
        }
export function useRolesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RolesPageQuery, RolesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RolesPageQuery, RolesPageQueryVariables>(RolesPageDocument, options);
        }
export type RolesPageQueryHookResult = ReturnType<typeof useRolesPageQuery>;
export type RolesPageLazyQueryHookResult = ReturnType<typeof useRolesPageLazyQuery>;
export type RolesPageSuspenseQueryHookResult = ReturnType<typeof useRolesPageSuspenseQuery>;
export type RolesPageQueryResult = Apollo.QueryResult<RolesPageQuery, RolesPageQueryVariables>;
export const GetTenantSettingsDocument = gql`
    query GetTenantSettings($tenantId: ID!) {
  tenantById(tenantId: $tenantId) {
    id
    ...TenantLayout
    name
    emailDomain
    userAccess {
      tenantPermissions
    }
  }
}
    ${TenantLayoutFragmentDoc}`;

/**
 * __useGetTenantSettingsQuery__
 *
 * To run a query within a React component, call `useGetTenantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantSettingsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetTenantSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables> & ({ variables: GetTenantSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
      }
export function useGetTenantSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
        }
export function useGetTenantSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>(GetTenantSettingsDocument, options);
        }
export type GetTenantSettingsQueryHookResult = ReturnType<typeof useGetTenantSettingsQuery>;
export type GetTenantSettingsLazyQueryHookResult = ReturnType<typeof useGetTenantSettingsLazyQuery>;
export type GetTenantSettingsSuspenseQueryHookResult = ReturnType<typeof useGetTenantSettingsSuspenseQuery>;
export type GetTenantSettingsQueryResult = Apollo.QueryResult<GetTenantSettingsQuery, GetTenantSettingsQueryVariables>;
export const TenantSettingsDocument = gql`
    mutation TenantSettings($input: TenantEditInput!) {
  tenantEdit(input: $input) {
    tenant {
      id
      name
      emailDomain
    }
    errors {
      code: __typename
    }
  }
}
    `;
export type TenantSettingsMutationFn = Apollo.MutationFunction<TenantSettingsMutation, TenantSettingsMutationVariables>;

/**
 * __useTenantSettingsMutation__
 *
 * To run a mutation, you first call `useTenantSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantSettingsMutation, { data, loading, error }] = useTenantSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTenantSettingsMutation(baseOptions?: Apollo.MutationHookOptions<TenantSettingsMutation, TenantSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantSettingsMutation, TenantSettingsMutationVariables>(TenantSettingsDocument, options);
      }
export type TenantSettingsMutationHookResult = ReturnType<typeof useTenantSettingsMutation>;
export type TenantSettingsMutationResult = Apollo.MutationResult<TenantSettingsMutation>;
export type TenantSettingsMutationOptions = Apollo.BaseMutationOptions<TenantSettingsMutation, TenantSettingsMutationVariables>;
export const WaterStationDataTableByTenantDocument = gql`
    query WaterStationDataTableByTenant($tenantId: ID!, $after: String, $where: WaterStationProjectionFilterInput, $order: [WaterStationProjectionSortInput!]) {
  tenantById(tenantId: $tenantId) {
    ...TenantLayout
    waterStations(first: 200, after: $after, where: $where, order: $order) {
      ...WaterStationDataTable
    }
  }
}
    ${TenantLayoutFragmentDoc}
${WaterStationDataTableFragmentDoc}`;

/**
 * __useWaterStationDataTableByTenantQuery__
 *
 * To run a query within a React component, call `useWaterStationDataTableByTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterStationDataTableByTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterStationDataTableByTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWaterStationDataTableByTenantQuery(baseOptions: Apollo.QueryHookOptions<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables> & ({ variables: WaterStationDataTableByTenantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables>(WaterStationDataTableByTenantDocument, options);
      }
export function useWaterStationDataTableByTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables>(WaterStationDataTableByTenantDocument, options);
        }
export function useWaterStationDataTableByTenantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables>(WaterStationDataTableByTenantDocument, options);
        }
export type WaterStationDataTableByTenantQueryHookResult = ReturnType<typeof useWaterStationDataTableByTenantQuery>;
export type WaterStationDataTableByTenantLazyQueryHookResult = ReturnType<typeof useWaterStationDataTableByTenantLazyQuery>;
export type WaterStationDataTableByTenantSuspenseQueryHookResult = ReturnType<typeof useWaterStationDataTableByTenantSuspenseQuery>;
export type WaterStationDataTableByTenantQueryResult = Apollo.QueryResult<WaterStationDataTableByTenantQuery, WaterStationDataTableByTenantQueryVariables>;
export const OrganisationJoinDocument = gql`
    query OrganisationJoin($code: String!) {
  tenantInvite(joinCode: $code) {
    code
    tenantId
    tenantName
    canConsume
  }
}
    `;

/**
 * __useOrganisationJoinQuery__
 *
 * To run a query within a React component, call `useOrganisationJoinQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationJoinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationJoinQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useOrganisationJoinQuery(baseOptions: Apollo.QueryHookOptions<OrganisationJoinQuery, OrganisationJoinQueryVariables> & ({ variables: OrganisationJoinQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationJoinQuery, OrganisationJoinQueryVariables>(OrganisationJoinDocument, options);
      }
export function useOrganisationJoinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationJoinQuery, OrganisationJoinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationJoinQuery, OrganisationJoinQueryVariables>(OrganisationJoinDocument, options);
        }
export function useOrganisationJoinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationJoinQuery, OrganisationJoinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationJoinQuery, OrganisationJoinQueryVariables>(OrganisationJoinDocument, options);
        }
export type OrganisationJoinQueryHookResult = ReturnType<typeof useOrganisationJoinQuery>;
export type OrganisationJoinLazyQueryHookResult = ReturnType<typeof useOrganisationJoinLazyQuery>;
export type OrganisationJoinSuspenseQueryHookResult = ReturnType<typeof useOrganisationJoinSuspenseQuery>;
export type OrganisationJoinQueryResult = Apollo.QueryResult<OrganisationJoinQuery, OrganisationJoinQueryVariables>;
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($input: TenantAcceptInviteInput!) {
  tenantAcceptInvite(input: $input) {
    tenant {
      id
      name
    }
  }
}
    `;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, options);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const NewTenantPageDocument = gql`
    query NewTenantPage {
  me {
    id
    email
  }
}
    `;

/**
 * __useNewTenantPageQuery__
 *
 * To run a query within a React component, call `useNewTenantPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewTenantPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewTenantPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewTenantPageQuery(baseOptions?: Apollo.QueryHookOptions<NewTenantPageQuery, NewTenantPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewTenantPageQuery, NewTenantPageQueryVariables>(NewTenantPageDocument, options);
      }
export function useNewTenantPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewTenantPageQuery, NewTenantPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewTenantPageQuery, NewTenantPageQueryVariables>(NewTenantPageDocument, options);
        }
export function useNewTenantPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewTenantPageQuery, NewTenantPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewTenantPageQuery, NewTenantPageQueryVariables>(NewTenantPageDocument, options);
        }
export type NewTenantPageQueryHookResult = ReturnType<typeof useNewTenantPageQuery>;
export type NewTenantPageLazyQueryHookResult = ReturnType<typeof useNewTenantPageLazyQuery>;
export type NewTenantPageSuspenseQueryHookResult = ReturnType<typeof useNewTenantPageSuspenseQuery>;
export type NewTenantPageQueryResult = Apollo.QueryResult<NewTenantPageQuery, NewTenantPageQueryVariables>;
export const CreateTenantDocument = gql`
    mutation CreateTenant($input: TenantCreateOrganisationInput!) {
  tenantCreateOrganisation(input: $input) {
    tenant {
      id
      name
    }
    errors {
      code: __typename
    }
  }
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const GetTenantsDocument = gql`
    query GetTenants {
  tenants(first: 50) {
    nodes {
      ...GetTenantsDetailShort
    }
  }
}
    ${GetTenantsDetailShortFragmentDoc}`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export function useGetTenantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsSuspenseQueryHookResult = ReturnType<typeof useGetTenantsSuspenseQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetKnownTagsDocument = gql`
    query GetKnownTags {
  knownTags {
    id
    key
  }
}
    `;

/**
 * __useGetKnownTagsQuery__
 *
 * To run a query within a React component, call `useGetKnownTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnownTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnownTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKnownTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetKnownTagsQuery, GetKnownTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnownTagsQuery, GetKnownTagsQueryVariables>(GetKnownTagsDocument, options);
      }
export function useGetKnownTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnownTagsQuery, GetKnownTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnownTagsQuery, GetKnownTagsQueryVariables>(GetKnownTagsDocument, options);
        }
export function useGetKnownTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetKnownTagsQuery, GetKnownTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKnownTagsQuery, GetKnownTagsQueryVariables>(GetKnownTagsDocument, options);
        }
export type GetKnownTagsQueryHookResult = ReturnType<typeof useGetKnownTagsQuery>;
export type GetKnownTagsLazyQueryHookResult = ReturnType<typeof useGetKnownTagsLazyQuery>;
export type GetKnownTagsSuspenseQueryHookResult = ReturnType<typeof useGetKnownTagsSuspenseQuery>;
export type GetKnownTagsQueryResult = Apollo.QueryResult<GetKnownTagsQuery, GetKnownTagsQueryVariables>;
export const WaterStationHistoryPageDocument = gql`
    query WaterStationHistoryPage($id: ID!) {
  waterStationById(id: $id) {
    ...WaterStationLayout
    history {
      nodes {
        code: __typename
        time
        user {
          avatar
          displayName
          email
        }
        ... on DispatchedHistoryItem {
          date
        }
        ... on RenamedHistoryItem {
          name
        }
        ... on TagsAddedHistoryItem {
          items {
            key
            value
          }
        }
        ... on TagsRemovedHistoryItem {
          items {
            key
            value
          }
        }
        ... on LocationChangedHistoryItem {
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
}
    ${WaterStationLayoutFragmentDoc}`;

/**
 * __useWaterStationHistoryPageQuery__
 *
 * To run a query within a React component, call `useWaterStationHistoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterStationHistoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterStationHistoryPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWaterStationHistoryPageQuery(baseOptions: Apollo.QueryHookOptions<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables> & ({ variables: WaterStationHistoryPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables>(WaterStationHistoryPageDocument, options);
      }
export function useWaterStationHistoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables>(WaterStationHistoryPageDocument, options);
        }
export function useWaterStationHistoryPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables>(WaterStationHistoryPageDocument, options);
        }
export type WaterStationHistoryPageQueryHookResult = ReturnType<typeof useWaterStationHistoryPageQuery>;
export type WaterStationHistoryPageLazyQueryHookResult = ReturnType<typeof useWaterStationHistoryPageLazyQuery>;
export type WaterStationHistoryPageSuspenseQueryHookResult = ReturnType<typeof useWaterStationHistoryPageSuspenseQuery>;
export type WaterStationHistoryPageQueryResult = Apollo.QueryResult<WaterStationHistoryPageQuery, WaterStationHistoryPageQueryVariables>;
export const GetWaterStationByIdDocument = gql`
    query GetWaterStationById($id: ID!) {
  waterStationById(id: $id) {
    ...WaterStationLayout
    id
    serial
    usage {
      allTime
      today
      thisWeek
      thisMonth
    }
    name
    serial
    model
    dispatchDate
    tags {
      key
      value
    }
    location {
      latitude
      longitude
    }
    imageHref
  }
}
    ${WaterStationLayoutFragmentDoc}`;

/**
 * __useGetWaterStationByIdQuery__
 *
 * To run a query within a React component, call `useGetWaterStationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaterStationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaterStationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaterStationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables> & ({ variables: GetWaterStationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables>(GetWaterStationByIdDocument, options);
      }
export function useGetWaterStationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables>(GetWaterStationByIdDocument, options);
        }
export function useGetWaterStationByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables>(GetWaterStationByIdDocument, options);
        }
export type GetWaterStationByIdQueryHookResult = ReturnType<typeof useGetWaterStationByIdQuery>;
export type GetWaterStationByIdLazyQueryHookResult = ReturnType<typeof useGetWaterStationByIdLazyQuery>;
export type GetWaterStationByIdSuspenseQueryHookResult = ReturnType<typeof useGetWaterStationByIdSuspenseQuery>;
export type GetWaterStationByIdQueryResult = Apollo.QueryResult<GetWaterStationByIdQuery, GetWaterStationByIdQueryVariables>;
export const WaterStationReadingEntryPageDocument = gql`
    query WaterStationReadingEntryPage($id: ID!) {
  waterStationById(id: $id) {
    id
    name
    model
    serial
    ...WaterStationLayout
    sensor {
      __typename
      ... on NoWaterStationSensor {
        ...NoWaterStationSensorForm
      }
      ... on WaterStationUC500Sensor {
        ...WaterStationUC500SensorForm
      }
      ... on WaterStationEM300Sensor {
        ...WaterStationEM300SensorForm
      }
    }
  }
}
    ${WaterStationLayoutFragmentDoc}
${NoWaterStationSensorFormFragmentDoc}
${WaterStationUc500SensorFormFragmentDoc}
${WaterStationEm300SensorFormFragmentDoc}`;

/**
 * __useWaterStationReadingEntryPageQuery__
 *
 * To run a query within a React component, call `useWaterStationReadingEntryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterStationReadingEntryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterStationReadingEntryPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWaterStationReadingEntryPageQuery(baseOptions: Apollo.QueryHookOptions<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables> & ({ variables: WaterStationReadingEntryPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables>(WaterStationReadingEntryPageDocument, options);
      }
export function useWaterStationReadingEntryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables>(WaterStationReadingEntryPageDocument, options);
        }
export function useWaterStationReadingEntryPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables>(WaterStationReadingEntryPageDocument, options);
        }
export type WaterStationReadingEntryPageQueryHookResult = ReturnType<typeof useWaterStationReadingEntryPageQuery>;
export type WaterStationReadingEntryPageLazyQueryHookResult = ReturnType<typeof useWaterStationReadingEntryPageLazyQuery>;
export type WaterStationReadingEntryPageSuspenseQueryHookResult = ReturnType<typeof useWaterStationReadingEntryPageSuspenseQuery>;
export type WaterStationReadingEntryPageQueryResult = Apollo.QueryResult<WaterStationReadingEntryPageQuery, WaterStationReadingEntryPageQueryVariables>;
export const AddReadingLitresDocument = gql`
    mutation addReadingLitres($input: WaterStationAddReadingLitresInput!) {
  waterStationAddReadingLitres(input: $input) {
    errors {
      __typename
    }
    waterStation {
      usage {
        allTime
      }
    }
  }
}
    `;
export type AddReadingLitresMutationFn = Apollo.MutationFunction<AddReadingLitresMutation, AddReadingLitresMutationVariables>;

/**
 * __useAddReadingLitresMutation__
 *
 * To run a mutation, you first call `useAddReadingLitresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReadingLitresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReadingLitresMutation, { data, loading, error }] = useAddReadingLitresMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReadingLitresMutation(baseOptions?: Apollo.MutationHookOptions<AddReadingLitresMutation, AddReadingLitresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReadingLitresMutation, AddReadingLitresMutationVariables>(AddReadingLitresDocument, options);
      }
export type AddReadingLitresMutationHookResult = ReturnType<typeof useAddReadingLitresMutation>;
export type AddReadingLitresMutationResult = Apollo.MutationResult<AddReadingLitresMutation>;
export type AddReadingLitresMutationOptions = Apollo.BaseMutationOptions<AddReadingLitresMutation, AddReadingLitresMutationVariables>;
export const AddReadingPulsesDocument = gql`
    mutation addReadingPulses($input: WaterStationAddReadingPulsesInput!) {
  waterStationAddReadingPulses(input: $input) {
    errors {
      __typename
    }
    waterStation {
      usage {
        allTime
      }
    }
  }
}
    `;
export type AddReadingPulsesMutationFn = Apollo.MutationFunction<AddReadingPulsesMutation, AddReadingPulsesMutationVariables>;

/**
 * __useAddReadingPulsesMutation__
 *
 * To run a mutation, you first call `useAddReadingPulsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReadingPulsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReadingPulsesMutation, { data, loading, error }] = useAddReadingPulsesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReadingPulsesMutation(baseOptions?: Apollo.MutationHookOptions<AddReadingPulsesMutation, AddReadingPulsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReadingPulsesMutation, AddReadingPulsesMutationVariables>(AddReadingPulsesDocument, options);
      }
export type AddReadingPulsesMutationHookResult = ReturnType<typeof useAddReadingPulsesMutation>;
export type AddReadingPulsesMutationResult = Apollo.MutationResult<AddReadingPulsesMutation>;
export type AddReadingPulsesMutationOptions = Apollo.BaseMutationOptions<AddReadingPulsesMutation, AddReadingPulsesMutationVariables>;
export const WaterStationsSettingsDocument = gql`
    query WaterStationsSettings($id: ID!) {
  knownTags {
    key
    values
    instances
  }
  waterStationById(id: $id) {
    id
    serial
    name
    imageHref
    ...WaterStationLayout
    location {
      latitude
      longitude
    }
    tags {
      value
      key
    }
  }
}
    ${WaterStationLayoutFragmentDoc}`;

/**
 * __useWaterStationsSettingsQuery__
 *
 * To run a query within a React component, call `useWaterStationsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterStationsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterStationsSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWaterStationsSettingsQuery(baseOptions: Apollo.QueryHookOptions<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables> & ({ variables: WaterStationsSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables>(WaterStationsSettingsDocument, options);
      }
export function useWaterStationsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables>(WaterStationsSettingsDocument, options);
        }
export function useWaterStationsSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables>(WaterStationsSettingsDocument, options);
        }
export type WaterStationsSettingsQueryHookResult = ReturnType<typeof useWaterStationsSettingsQuery>;
export type WaterStationsSettingsLazyQueryHookResult = ReturnType<typeof useWaterStationsSettingsLazyQuery>;
export type WaterStationsSettingsSuspenseQueryHookResult = ReturnType<typeof useWaterStationsSettingsSuspenseQuery>;
export type WaterStationsSettingsQueryResult = Apollo.QueryResult<WaterStationsSettingsQuery, WaterStationsSettingsQueryVariables>;
export const EditWaterStationDocument = gql`
    mutation EditWaterStation($input: WaterStationEditDetailInput!) {
  waterStationEditDetail(input: $input) {
    waterStation {
      id
      name
      location {
        latitude
        longitude
      }
      tags {
        key
        value
      }
    }
    errors {
      __typename
    }
  }
}
    `;
export type EditWaterStationMutationFn = Apollo.MutationFunction<EditWaterStationMutation, EditWaterStationMutationVariables>;

/**
 * __useEditWaterStationMutation__
 *
 * To run a mutation, you first call `useEditWaterStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWaterStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWaterStationMutation, { data, loading, error }] = useEditWaterStationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditWaterStationMutation(baseOptions?: Apollo.MutationHookOptions<EditWaterStationMutation, EditWaterStationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWaterStationMutation, EditWaterStationMutationVariables>(EditWaterStationDocument, options);
      }
export type EditWaterStationMutationHookResult = ReturnType<typeof useEditWaterStationMutation>;
export type EditWaterStationMutationResult = Apollo.MutationResult<EditWaterStationMutation>;
export type EditWaterStationMutationOptions = Apollo.BaseMutationOptions<EditWaterStationMutation, EditWaterStationMutationVariables>;
export const GetActivationCodeDocument = gql`
    query GetActivationCode($code: String!) {
  activationCodeById(code: $code) {
    availableTenants {
      nodes {
        id
        name
      }
    }
    items {
      ...GetActivationCodeItem
    }
  }
}
    ${GetActivationCodeItemFragmentDoc}`;

/**
 * __useGetActivationCodeQuery__
 *
 * To run a query within a React component, call `useGetActivationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetActivationCodeQuery(baseOptions: Apollo.QueryHookOptions<GetActivationCodeQuery, GetActivationCodeQueryVariables> & ({ variables: GetActivationCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivationCodeQuery, GetActivationCodeQueryVariables>(GetActivationCodeDocument, options);
      }
export function useGetActivationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivationCodeQuery, GetActivationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivationCodeQuery, GetActivationCodeQueryVariables>(GetActivationCodeDocument, options);
        }
export function useGetActivationCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActivationCodeQuery, GetActivationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActivationCodeQuery, GetActivationCodeQueryVariables>(GetActivationCodeDocument, options);
        }
export type GetActivationCodeQueryHookResult = ReturnType<typeof useGetActivationCodeQuery>;
export type GetActivationCodeLazyQueryHookResult = ReturnType<typeof useGetActivationCodeLazyQuery>;
export type GetActivationCodeSuspenseQueryHookResult = ReturnType<typeof useGetActivationCodeSuspenseQuery>;
export type GetActivationCodeQueryResult = Apollo.QueryResult<GetActivationCodeQuery, GetActivationCodeQueryVariables>;
export const ConsumeActivationCodeDocument = gql`
    mutation consumeActivationCode($input: TenantConsumeActivationCodeInput!) {
  tenantConsumeActivationCode(input: $input) {
    unitIds
    errors {
      code: __typename
    }
  }
}
    `;
export type ConsumeActivationCodeMutationFn = Apollo.MutationFunction<ConsumeActivationCodeMutation, ConsumeActivationCodeMutationVariables>;

/**
 * __useConsumeActivationCodeMutation__
 *
 * To run a mutation, you first call `useConsumeActivationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeActivationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeActivationCodeMutation, { data, loading, error }] = useConsumeActivationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumeActivationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ConsumeActivationCodeMutation, ConsumeActivationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumeActivationCodeMutation, ConsumeActivationCodeMutationVariables>(ConsumeActivationCodeDocument, options);
      }
export type ConsumeActivationCodeMutationHookResult = ReturnType<typeof useConsumeActivationCodeMutation>;
export type ConsumeActivationCodeMutationResult = Apollo.MutationResult<ConsumeActivationCodeMutation>;
export type ConsumeActivationCodeMutationOptions = Apollo.BaseMutationOptions<ConsumeActivationCodeMutation, ConsumeActivationCodeMutationVariables>;
export const CreateActivationCodeDocument = gql`
    mutation CreateActivationCode($input: ActivationCodeCreateInput!) {
  activationCodeCreate(input: $input) {
    code
    errors {
      code: __typename
      ... on CodeExistsError {
        serial
      }
      ... on OwnedUnitError {
        serial
      }
      ... on InvalidSerialError {
        serial
      }
    }
  }
}
    `;
export type CreateActivationCodeMutationFn = Apollo.MutationFunction<CreateActivationCodeMutation, CreateActivationCodeMutationVariables>;

/**
 * __useCreateActivationCodeMutation__
 *
 * To run a mutation, you first call `useCreateActivationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivationCodeMutation, { data, loading, error }] = useCreateActivationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivationCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivationCodeMutation, CreateActivationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivationCodeMutation, CreateActivationCodeMutationVariables>(CreateActivationCodeDocument, options);
      }
export type CreateActivationCodeMutationHookResult = ReturnType<typeof useCreateActivationCodeMutation>;
export type CreateActivationCodeMutationResult = Apollo.MutationResult<CreateActivationCodeMutation>;
export type CreateActivationCodeMutationOptions = Apollo.BaseMutationOptions<CreateActivationCodeMutation, CreateActivationCodeMutationVariables>;
export const WaterStationDataTableDocument = gql`
    query WaterStationDataTable($after: String, $where: WaterStationProjectionFilterInput, $order: [WaterStationProjectionSortInput!]) {
  waterStations(first: 100, after: $after, where: $where, order: $order) {
    ...WaterStationDataTable
  }
}
    ${WaterStationDataTableFragmentDoc}`;

/**
 * __useWaterStationDataTableQuery__
 *
 * To run a query within a React component, call `useWaterStationDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterStationDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterStationDataTableQuery({
 *   variables: {
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWaterStationDataTableQuery(baseOptions?: Apollo.QueryHookOptions<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>(WaterStationDataTableDocument, options);
      }
export function useWaterStationDataTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>(WaterStationDataTableDocument, options);
        }
export function useWaterStationDataTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>(WaterStationDataTableDocument, options);
        }
export type WaterStationDataTableQueryHookResult = ReturnType<typeof useWaterStationDataTableQuery>;
export type WaterStationDataTableLazyQueryHookResult = ReturnType<typeof useWaterStationDataTableLazyQuery>;
export type WaterStationDataTableSuspenseQueryHookResult = ReturnType<typeof useWaterStationDataTableSuspenseQuery>;
export type WaterStationDataTableQueryResult = Apollo.QueryResult<WaterStationDataTableQuery, WaterStationDataTableQueryVariables>;
export const WaterStationGroupedTableDocument = gql`
    query WaterStationGroupedTable($tag: String!) {
  waterStationsByTag(tag: $tag) {
    nodes {
      __typename
      ... on TaggedWaterStationGroup {
        tag
        waterStations(first: 100) {
          ...WaterStationDataTable
        }
      }
      ... on UntaggedWaterStationGroup {
        waterStations(first: 100) {
          ...WaterStationDataTable
        }
      }
    }
  }
}
    ${WaterStationDataTableFragmentDoc}`;

/**
 * __useWaterStationGroupedTableQuery__
 *
 * To run a query within a React component, call `useWaterStationGroupedTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterStationGroupedTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterStationGroupedTableQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useWaterStationGroupedTableQuery(baseOptions: Apollo.QueryHookOptions<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables> & ({ variables: WaterStationGroupedTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables>(WaterStationGroupedTableDocument, options);
      }
export function useWaterStationGroupedTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables>(WaterStationGroupedTableDocument, options);
        }
export function useWaterStationGroupedTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables>(WaterStationGroupedTableDocument, options);
        }
export type WaterStationGroupedTableQueryHookResult = ReturnType<typeof useWaterStationGroupedTableQuery>;
export type WaterStationGroupedTableLazyQueryHookResult = ReturnType<typeof useWaterStationGroupedTableLazyQuery>;
export type WaterStationGroupedTableSuspenseQueryHookResult = ReturnType<typeof useWaterStationGroupedTableSuspenseQuery>;
export type WaterStationGroupedTableQueryResult = Apollo.QueryResult<WaterStationGroupedTableQuery, WaterStationGroupedTableQueryVariables>;